import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private _cookieService: CookieService,
    private route: ActivatedRoute,
  ) { }

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): boolean {
  //   const url: string = state.url;
  //   if (!this.authService.routingMach(url)) {
  //     this.router.navigate(["/signin"]).then((_) => false);
  //   }
  //   return this.checkLogin(url);
  // }
  paramName: string = null;
  parentName: string = null;
  user: any;
  canActivate(state: RouterStateSnapshot): boolean {
    const url: string = state.url;
    this.parentName = state.root.queryParams['parent'];
    this.user = Object.keys(this._cookieService.getAll()).length > 0 ? JSON.parse(this._cookieService.getAll()?.user_info) : null;
    if (!this.user || Object.keys(this.user).length === 0) {
      if (this.parentName) {
        this.router.navigate(["/home-page/login", { parent: this.parentName }]).then((_) => false);
      } else {
        this.router.navigate(["/home-page/login"]).then((_) => false);
      }
    }
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    const dt = this.tokenService.getCookieExpired();
    if (dt !== null || dt !== undefined || dt > new Date()) {
      return true;
    }
    this.authService.redirectUrl = url;
    this.router.navigate(["/home-page/login"]).then((_) => false);
  }
}
