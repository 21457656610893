import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-users-cog',
  templateUrl: './icon-users-cog.component.html',
  styleUrls: ['./icon-users-cog.component.scss'],
})
export class IconUsersCogComponent {
  constructor() {}
}
