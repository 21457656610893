import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { AuthService } from '../../@core/auth/auth.service';
import { MenuService } from '../../@core/customs/menu.service';
import { SUCCESS_RESPONSE } from '../../@core/customs/constants';
import { MenuVm } from '../../@core/data/qldt.service';

@Component({
  selector: "ngx-menu",
  styleUrls: ["menu.component.scss"],
  templateUrl: "./menu.component.html",
})
export class MenuComponent implements OnInit {
  menu: NbMenuItem[] = [];

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    let checkLoadMenu = false;
    this.menuService.getNbMenuItem().subscribe((result) => {
      console.log("result", result);
      if (result.length !== 0 || checkLoadMenu) {
        this.menu = result;
      } else {
        this.menuService.getMenuByUserLogin().subscribe(
          (menu) => {
            if (menu.code === SUCCESS_RESPONSE) {
              this.loadMenu(menu.data);
              this.menuService.setRoutingSaveCheckRole(menu.data);
              checkLoadMenu = true;
            }
          },
          () => {
            this.authService.logout();
            this.router.navigate(["/home-page/login"]);
          }
        );
      }
    });
  }

  loadMenu(lst: MenuVm[]) {
    lst
      .filter((c) => c.parentId === null)
      .forEach((element) => {
        const itemMenu = new NbMenuItem();
        itemMenu.icon = element.icon;
        itemMenu.title = element.name;
        itemMenu.link = element.route;
        if (lst.findIndex((c) => c.parentId === element.id) !== -1) {
          itemMenu.children = this.loadMenuChildrent(element, lst);
        }
        this.menu.push(itemMenu);
      });
    this.menuService.setNbMenuItem(this.menu);
  }

  loadMenuChildrent(item: MenuVm, lst: MenuVm[]) {
    const result = [];
    lst
      .filter((c) => c.parentId === item.id)
      .forEach((element) => {
        const itemChilrent = new NbMenuItem();
        itemChilrent.icon = element.icon;
        itemChilrent.title = element.name;
        itemChilrent.link = element.route;
        if (lst.findIndex((t) => t.parentId === element.id) !== -1) {
          itemChilrent.children = this.loadMenuChildrent(element, lst);
        }
        result.push(itemChilrent);
      });
    return result;
  }

  // menu = MENU_ITEMS;

  // NbMenuItem[] = [
  //   {
  //     title: 'Dashboard',
  //     icon: 'home-outline',
  //     link: '/pages/dashboard',
  //     home: true,
  //   },
  //   {
  //     title: 'FEATURES',
  //     group: true,
  //   },
  //   {
  //     title: 'Auth',
  //     icon: 'lock-outline',
  //     children: [
  //       {
  //         title: 'Login',
  //         link: '/auth/login',
  //       },
  //       {
  //         title: 'Register',
  //         link: '/auth/register',
  //       },
  //       {
  //         title: 'Request Password',
  //         link: '/auth/request-password',
  //       },
  //       {
  //         title: 'Reset Password',
  //         link: '/auth/reset-password',
  //       },
  //     ],
  //   },
}
