import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResponse, DecimalApiResponse, FileResponse, ItemSysuserIEnumerablePageModelViewApiResponse, ObjectIEnumerableApiResponse, ObjectIEnumerablePageModelViewApiResponse, PageModel, ProcessInsertFileCustomModel, ProcessUpdateCustomFileModel, QldtApiService, SelectOneWithFileCustomModelApiResponse, SysUserPageModel, SysuserEntity, SysuserEntityApiResponse } from "../../../@core/data/qldt.service";

@Injectable({
  providedIn: 'root'
})
export class CanBoService {

  constructor(private api: QldtApiService) {}
  paging(body: PageModel): Observable<ObjectIEnumerablePageModelViewApiResponse> {
    return this.api.qldtApiV1SysUserPaging(body);
  }
  pagingCustom(body: SysUserPageModel): Observable<ItemSysuserIEnumerablePageModelViewApiResponse> {
    return this.api.qldtApiV1SysUserPagingSysuserModel(body);
  }
  selectOne(key: number): Observable<SysuserEntityApiResponse> {
    return this.api.qldtApiV1SysUserSelectOne(key);
  }

  insert(body: SysuserEntity): Observable<ApiResponse> {
    return this.api.qldtApiV1SysUserInsert(body);
  }

  update(body: SysuserEntity): Observable<ApiResponse> {
    return this.api.qldtApiV1SysUserUpdate(body);
  }

  delete(key: number): Observable<ApiResponse> {
    return this.api.qldtApiV1SysUserDelete(key);
  }

  downloadTemplateFile(type: number): Observable<FileResponse> {
    return this.api.qldtApiV1SysUserDownloadTemplateUser(type);
  }

  insertListData(body: any[]): Observable<ApiResponse> {
    return this.api.qldtApiV1SysUserInsertListData(body);
  }

  selectAll(): Observable<ObjectIEnumerableApiResponse> {
    return this.api.qldtApiV1SysUserSelectAllBase();
  }
  insertWithFile(body: ProcessInsertFileCustomModel): Observable<DecimalApiResponse> {
    return this.api.qldtApiV1SysUserInsertCustomWithFile(body);
  }

  updateWithFile(body: ProcessUpdateCustomFileModel): Observable<ApiResponse> {
    return this.api.qldtApiV1SysUserUpdateCustomWithFile(body);
  }

  selectOneWithFile(type: number, id: number): Observable<SelectOneWithFileCustomModelApiResponse> {
    return this.api.qldtApiV1SysUserSelectOneCustomWithFile(type, id);
  }

  deleteWithFile(id: number): Observable<ApiResponse> {
    return this.api.qldtApiV1SysUserDeleteWithFile(id);
  }
  ChangeStatus(id: number): Observable<ApiResponse> {
    return this.api.qldtApiV1SysUserChangeStatus(id);
  }
  //#region  select all academic
  HocViSelectAll(): Observable<ObjectIEnumerableApiResponse> {
    return this.api.qldtApiV1HocViSelectAll();
  }
  Search(body: PageModel): Observable<ObjectIEnumerablePageModelViewApiResponse> {
    return this.api.qldtApiV1SysUserSearch(body);
  }
  //#endregion
}
