import { Component } from '@angular/core';

@Component({
  selector: 'ngx-chevron-down',
  templateUrl: './chevron-down.component.html',
  styleUrls: ['./chevron-down.component.scss']
})
export class ChevronDownComponent  {

  constructor() { }

}
