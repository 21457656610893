<nb-card>
  <nb-card-header class="nb-card-header">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
        Hướng dẫn sử dụng
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-center">
        HDSD Phần mềm QLDT.docx
        <!-- <ngx-btn-download (click)="downloadTemplateFile(typeWeb);" data-toggle="tooltip"
          title="Tải hướng dẫn sử dụng phần mềm QLĐT"></ngx-btn-download>&nbsp; -->

      </div>
    </div>
  </nb-card-body>

  <nb-card-footer class="text-right">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
        <ngx-btn-download (click)="downloadTemplateFile();" data-toggle="tooltip"
          title="Tải hướng dẫn sử dụng phần mềm Web và ứng dụng Mobile QLĐT"></ngx-btn-download>
          &nbsp;
        <ngx-btn-close mat-dialog-close></ngx-btn-close>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
