import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-share',
    templateUrl: './icon-share.component.html',
    styleUrls: ['./icon-share.component.scss']
})
export class IconShareComponent {
    constructor() {
    }
}
