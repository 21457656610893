import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-search-minus',
  templateUrl: './icon-search-minus.component.html',
  styleUrls: ['./icon-search-minus.component.scss'],
})
export class IconSearchMinusComponent {
  constructor() {}
}
