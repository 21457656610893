import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-approve',
    templateUrl: './icon-approve.component.html',
    styleUrls: ['./icon-approve.component.scss']
})
export class IconApproveComponent {
    constructor() {
    }
}
