import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ChangePasswordModel, QldtApiService } from '../../../../@core/data/qldt.service';


@Injectable({
    providedIn: 'root',
})
export class ChangePasswordService {
    constructor (
        private api: QldtApiService
    ) {}

    changePassword(oldPassword: string, newPassword: string): Observable<ApiResponse> {
        const body = new ChangePasswordModel({
            newPassword: newPassword,
            oldPassword: oldPassword
        });
        return this.api.qldtApiV1SysUserChangePassword(body);
    }
}
