import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-decline',
    templateUrl: './icon-decline.component.html',
    styleUrls: ['./icon-decline.component.scss']
})
export class IconDeclineComponent {
    constructor() {
    }
}
