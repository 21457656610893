import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-minus',
  templateUrl: './icon-minus.component.html',
  styleUrls: ['./icon-minus.component.scss']
})
export class IconMinusComponent  {

  constructor() { }

}
