import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbInputDirective, NbMediaBreakpointsService, NbMenuService, NbPopoverComponent, NbPopoverDirective, NbPopoverModule, NbSidebarService, NbThemeService, NbWindowControlButtonsConfig, NbWindowService, NbWindowState } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { TokenService } from '../../../@core/auth/token.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from '../../../@core/customs/common.service';
import { AuthService } from '../../../@core/auth/auth.service';
import { ChangePasswordComponent } from '../../../module/dmht/password/change-password/change-password.component';
import { UserInfoComponent } from '../../../module/dmht/User-info/user-info/user-info.component';
import { environment } from '../../../../environments/environment';
import { imgDefault } from '../../../@core/customs/constants';
import { ItemSysuser, NotificationSelectModel, QldtApiService } from '../../../@core/data/qldt.service';
import { SUCCESS_RESPONSE } from '../../../@core/customs/constants';
import { NhatKyHeThongComponent } from '../../../module/qtht/thong-ke-truy-cap/nhat-ky-he-thong/nhat-ky-he-thong.component';
import { ChatComponent } from '../chat/chat.component';
import { Socket } from 'ngx-socket-io';
import { HuongDanSuDungComponent } from '../../../module/dmht/huong-dan-sd/huong-dan-sd.component';

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: "default",
      name: "Sáng",
    },
    {
      value: "dark",
      name: "Tối",
    },
    // {
    //   value: "cosmic",
    //   name: "Cosmic",
    // },
    // {
    //   value: "corporate",
    //   name: "Corporate",
    // },
  ];

  currentTheme = "cosmic";

  userMenu = [{ title: "Thông tin tài khoản" }, { title: 'Đổi mật khẩu' }, { title: 'Hướng dẫn sử dụng' }, { title: 'Lịch sử truy cập hệ thống' }, { title: "Đăng xuất" }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private tokenService: TokenService,
    private router: Router,
    private dialog: MatDialog,
    private socket: Socket,
    private commonService: CommonService,
    private authService: AuthService,
    private qldtService: QldtApiService,
    private windowService: NbWindowService
  ) { }

  path: string = environment.QLDT_PATH;
  imageDefault: string = imgDefault;
  ListUserOnlSocket: string[] = [];

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event): void {
    this.socket.emit("removeUserId", this.user.id.toString());
    this.socket.disconnect();
  }

  SocketGetUser() {
    this.socket.emit('userStatus', this.user.id.toString());
    this.socket.fromEvent('userStatus').subscribe(lstUserOnline => {
      console.log('----:---- ' + JSON.stringify(lstUserOnline));
    });

    this.receiveListUser().subscribe(res => {
      this.ListUserOnlSocket = res['ListUser'];
    });
  }

  async ngOnInit() {
    this.getNotification();
    this.currentTheme = this.themeService.currentTheme;
    this.tokenService.getUserInfo().subscribe(async (users: any) => {
      this.user = users;
      await this.SocketGetUser();
      this.loadDataMessage();

      this.user['picture'] = localStorage.getItem('img') ? localStorage.getItem('img').toString() : this.imageDefault;
      if (this.user === null || this.user === undefined) {
        this.router.navigate(["/home-page/login"]);
      }
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }



  receiveListUser() {
    return new Observable((observer) => {
      this.socket.on('listUserOnline', (message) => {
        observer.next(message);
      });
    });
  }

  ngOnDestroy() {
    this.socket.emit("removeUserId", this.user.id.toString());
    this.socket.disconnect();
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  selectToolbar() {
    const menuService = new NbMenuService();
    let status = true;
    menuService.onItemClick().subscribe((menu) => {
      switch (menu.item.title) {
        case "Thông tin tài khoản":
          if (status) {
            const data = {
              userId: this.user.id,
            };
            this.dialog.open(UserInfoComponent, this.commonService.configDialog('60%', { data: data }))
              .afterClosed().subscribe(() => { });
            status = false;
          }
          break;
        case "Lịch sử truy cập hệ thống":
          if (status) {
            this.dialog.open(NhatKyHeThongComponent, this.commonService.configDialog('50%', { data: this.user.id }))
              .afterClosed().subscribe(() => { });
            status = false;
          }
          break;
        case "Hướng dẫn sử dụng":
          if (status) {
            this.dialog.open(HuongDanSuDungComponent, this.commonService.configDialog('50%', {}))
              .afterClosed().subscribe(() => { });
            status = false;
          }
          break;
        case 'Đổi mật khẩu':
          if (status) {
            this.dialog.open(ChangePasswordComponent, this.commonService.configDialog('40%', {}))
              .afterClosed().subscribe(() => { });
            status = false;
          }
          break;
        case "Đăng xuất":
          if (status) {
            this.qldtService.qldtApiV1SyslogActionUpdateTimeEndSyslog().subscribe(rs => {
              this.authService.logout();
              this.router.navigate(["/home-page/login"]);
              status = false;
            });
          }
          break;
      }
    });
  }

  navigateHome() {
    this.socket.emit("removeUserId", this.user.id.toString());
    this.socket.disconnect();
    this.router.navigate(["/home-page"]).then(url => {
      location.reload();

    });
  }
  checkHasNewNoti: boolean = false;
  checkHasNewNotiMess: boolean = false;
  dataNoti: NotificationSelectModel[] = [];
  pagesize: number = 10;
  pagenumber: number = 1;
  getNotification() {
    this.qldtService.qldtApiV1NotificationSelectNoti(this.pagenumber, this.pagesize).subscribe(res => {
      if (res.code === SUCCESS_RESPONSE) {
        this.dataNoti = res.data;
        if (this.dataNoti.filter(c => c.status === 0).length > 0) {
          this.checkHasNewNoti = true;
        }

        if (this.dataNoti.filter(c => c.status === 0 && c.typeid === '1').length > 0) {
          this.checkHasNewNotiMess = true;
        }
      }
    });
  }

  placeholders: NotificationSelectModel[] = [];
  loading = false;
  totalNewNotification: number = 0;
  loadNext() {
    if (this.loading) { return }

    this.loading = true;
    this.placeholders = new Array(this.pagesize);
    this.qldtService.qldtApiV1NotificationSelectNoti(this.pagenumber, this.pagesize).subscribe(res => {
      if (res.code === SUCCESS_RESPONSE) {
        this.dataNoti.push(...res.data);
        this.placeholders = new Array(this.pagesize);
        this.loading = false;
        this.pagenumber++;
        if (this.dataNoti.filter(c => c.status === 0).length > 0) {
          this.checkHasNewNoti = true;
        }
        this.totalNewNotification = this.dataNoti.filter(c => c.status === 0).length;
      }
    });
  }

  checkClickBell: boolean = false;
  showNotification: boolean = false;
  clickBell() {
    this.showNotification = true;
    if (this.checkClickBell) {
      this.qldtService.qldtApiV1NotificationUpdateStatusTop10Noti(this.dataNoti).subscribe(res => {
        if (res.code === SUCCESS_RESPONSE) {
          this.checkHasNewNoti = false;
          this.getNotification();
        } else {
          console.log('Lỗi cập nhật thông báo !');
          this.pagenumber = 1;
        }
      });
    }
    this.checkClickBell = !this.checkClickBell;
  }

  deleteNotification(index: number, id: string) {
    this.qldtService.qldtApiV1NotificationDeleteNoti(id).subscribe(res => {
      if (res.code === SUCCESS_RESPONSE) {
        this.dataNoti.splice(index, 1);
      } else {
        this.commonService.toastrDanger('Lỗi xóa thông báo');
      }
    });
  }

  //   @ViewChild("templateRef") public templateRef: ElementRef;
  //   @HostListener('document:click')
  //   public handleClick(): void {
  //     if (this.templateRef?.isShown) {
  //       this.templateRef.hide();
  //     }
  //  }

  data: ItemSysuser[] = [];
  dataTemp: ItemSysuser[] = [];

  loadDataMessage() {
    this.qldtService.qldtApiV1SysUserSelectAllBase().subscribe(res => {
      if (res.code === SUCCESS_RESPONSE) {
        res.data.forEach(element => {
          element.image = element.image ? element.image : '../../../../assets/images/account.png';
        });
        let index = res.data.findIndex(c => c.id === this.user.id);
        res.data.splice(index, 1);
        this.data = res.data;
        this.dataTemp = res.data;
        this.data.forEach(element => {
          if (this.ListUserOnlSocket.includes(element.id.toString())) {
            element['isOnline'] = true;
          } else {
            element['isOnline'] = false;
          }
        });
      }
    });
  }

  dataUserTemplate = [];
  placeholdersUser = [];
  pageToLoadNext = 1;
  loadingUser = false;
  loadNextUser() {
    if (this.loadingUser) { return }
    this.loadingUser = true;
    this.placeholdersUser = new Array(20);
    var data = this.data.slice((this.pageToLoadNext - 1) * 20, this.pageToLoadNext * 20)
    this.placeholdersUser = [];
    this.dataUserTemplate.push(...data);
    this.loadingUser = false;
    this.pageToLoadNext++;
  }

  ListOpened: number[] = [];
  Chat(item: any) {
    let UserChat = this.data.find(c => c.id === item.id);
    if (!this.ListOpened.includes(UserChat.id)) {
      this.ListOpened.push(UserChat.id);

      const buttonsConfig: NbWindowControlButtonsConfig = {
        minimize: true,
        maximize: false,
        fullScreen: false,
        close: true,
      };

      const windowRef = this.windowService.open(ChatComponent, {
        title: item.fullname, context: {
          item: item
        }, initialState: NbWindowState.MAXIMIZED, buttons: buttonsConfig
      });
      windowRef.onClose.subscribe((visitor) => {
        let index = this.ListOpened.findIndex(c => c === UserChat.id);
        if (index !== -1) {
          this.ListOpened.splice(index, 1);
        }
      });
      this.showMessageBox = false;
    }
  }


  searchUser: string;
  async search() {
    await this.SocketGetUser();
    this.data.forEach(element => {
      if (this.ListUserOnlSocket.includes(element.id.toString())) {
        element['isOnline'] = true;
      } else {
        element['isOnline'] = false;
      }
    });
    if (this.searchUser) {
      this.dataUserTemplate = this.dataTemp.filter(c => c.fullname.toLowerCase().includes(this.searchUser.toLowerCase()) || c.teamsmail.toLowerCase().includes(this.searchUser.toLowerCase()));
    } else {
      this.dataUserTemplate = [];
      this.pageToLoadNext = 1;
      this.loadNextUser();
    }
  }

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @HostListener('document:click')
  public handleClick(): void {
    if (!this.popover?.isShown) {
      this.searchUser = null;
      this.data = this.dataTemp;
    } else {
      this.receiveListUser().subscribe(res => {
        this.ListUserOnlSocket = res['ListUser'];
      });
      this.loadDataMessage();
    }
  }

  clickNoti(item: any) {
    console.log(item);
    if (item.typeid === '1') {
      this.showNotification = false;
      let UserChat = this.data.find(c => c.id === item.senderid);
      if (UserChat['isOpned'] === false) {
        UserChat['isOpned'] = true;
        const windowRef = this.windowService.open(ChatComponent, {
          title: UserChat.fullname, context: {
            item: UserChat
          }, hasBackdrop: false, closeOnEsc: false, closeOnBackdropClick: false, initialState: NbWindowState.MAXIMIZED
        });
        windowRef.onClose.subscribe((visitor) => UserChat['isOpned'] = false);
      }
    }
  }

  showMessageBox: boolean = false;
  async changeActionMessage() {
    await this.SocketGetUser();
    this.data.forEach(element => {
      if (this.ListUserOnlSocket.includes(element.id.toString())) {
        element['isOnline'] = true;
      } else {
        element['isOnline'] = false;
      }
    });
    this.showMessageBox = true;
    if (this.pageToLoadNext === 1) {
      this.loadNextUser();
    }
  }
}
