import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-file-signature',
  templateUrl: './icon-file-signature.component.html',
  styleUrls: ['./icon-file-signature.component.scss'],
})
export class IconFileSignatureComponent {
  constructor() {}
}
