<div *ngIf="isLoadMessage" style="display: flex; justify-content: center; align-items: center;">
  <kendo-loader type="infinite-spinner" themeColor="primary" size="medium">
  </kendo-loader>
  <div class="k-loading-panel-text" style="color: #1274ac;">Đang tải tin nhắn...</div>
</div>
<kendo-chat #scrollContainer [messages]="messages" [user]="user1" #chat>
  <ng-template kendoChatAttachmentTemplate let-att>
    <div class="k-card" style="padding: 5px;">
      <a *ngIf="!att.subtitle" style="cursor: pointer;" (click)="downloadFile(att)">
        <span *ngIf="att.title.includes('doc')">
          <i class="fa-solid fa-file-word" style="color: #3a94d6;"></i>&nbsp;
          {{ att.title }}
        </span>
        <span *ngIf="att.title.includes('xls')">
          <i class="fa-solid fa-file-excel" style="color: #1c6c40;"></i>&nbsp;
          {{ att.title }}
        </span>
        <span *ngIf="att.title.includes('ppt')">
          <i class="fa-solid fa-file-powerpoint" style="color: #ca4222;"></i>&nbsp;
          {{ att.title }}
        </span>
        <span *ngIf="att.title.includes('pdf')">
          <i class="fa-solid fa-file-pdf" style="color: #ec0f02;"></i>&nbsp;
          {{ att.title }}
        </span>
        <span *ngIf="!att.title.includes('doc') && !att.title.includes('xls')
          && !att.title.includes('ppt') && !att.title.includes('pdf')">
          <i class="fa-solid fa-file" style="color: orange;"></i>&nbsp;
          {{ att.title }}
        </span>
      </a>
      <ng-container *ngIf="att.subtitle">
        <img class="image-class" [src]="att.subtitle" draggable="false" />
      </ng-container>
    </div>

  </ng-template>
  <ng-template kendoChatMessageBoxTemplate>
    <kendo-textarea [(ngModel)]="message" (inputFocus)="onInputFocus()" (inputBlur)="onInputBlur()"
      placeholder="Nhập tin nhắn..." resizable="none" [rows]="2" (valueChange)="changeText($event)"
      (keydown)="onKeyDown($event)" #messageBoxInput (keydown.enter)="sendMessage()">
      <kendo-textarea-suffix class="custom-suffix">
        <input (change)="onFileSelect($event)" #uploadInput type="file" style="display: none;" />
        <button (click)="uploadInput.click()" fillMode="flat" kendoButton disabled>
          <i class="fas fa-paperclip"></i>
        </button>
        <!-- <button (click)="uploadInput.click()" fillMode="flat" kendoButton>
          <i class="fas fa-image"></i>
        </button> -->
        <span class="k-flex-1"></span>
        <button kendoButton fillMode="clear" (click)="clearValue()" class="button-opacity">
          <i class="fas fa-times-circle"></i>
        </button>
        <kendo-input-separator class="k-text-base"></kendo-input-separator>
        <button kendoButton (click)="sendMessage()" fillMode="flat" class="send-button">
          Gửi
        </button>
      </kendo-textarea-suffix>
    </kendo-textarea>
    <!-- <button kendoButton (click)="sendMessage()" fillMode="flat" class="send-button">
      Gửi
    </button> -->
  </ng-template>
</kendo-chat>
<div *ngIf="!isHidden" class="chat-template" vertical='bottom' horizontal='end' style="overflow: scroll;">
  <kendo-listview [height]="200" [data]="dataChatTemplateData" containerClass="k-d-flex k-flex-col k-flex-nowrap">
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
      <div class="contact" (click)="ChooseTemplate(dataItem.content)" style="cursor: pointer;">
        <div class="contact-details">
          <div class="contact-email">
            <span style="margin-left: 11px;
            margin-right: 11px;
            background: #e5e2e2;
            padding: 3px;
            border-radius: 3px;"> /{{dataItem.recommend}}</span>
            <span>{{dataItem.content}}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-listview>
</div>
