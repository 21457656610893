import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService, ConvertDatePipe } from '../../../../@core/customs/common.service';
import { SUCCESS_RESPONSE, TypeStatus } from '../../../../@core/customs/constants';
import { QldtApiService, SysuserEntity, ThongTinNguoiDungModel } from '../../../../@core/data/qldt.service';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog/dialog-confirm/dialog-confirm.component';
import { UserInfoService } from './user-info.service';
import { ViewImageComponent } from './view-image/view-image.component';

@Component({
  selector: 'ngx-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private userInfoService: UserInfoService,
    private dialog: MatDialog,
    private dom: DomSanitizer,
    private organizationService: QldtApiService,
    @Inject(MAT_DIALOG_DATA) private userInfoItem: { data: any },
    private dialogRef: MatDialogRef<UserInfoComponent>
  ) {}

  target: ThongTinNguoiDungModel = new ThongTinNguoiDungModel();
  entity: SysuserEntity = new SysuserEntity();
  selectedFiles: any;
  fileReader = new FileReader();
  dataOrganization: any = {};

  ngOnInit(): void {
    this.LoadOrganization();
  }

  loadData(): void {
    this.userInfoService.GetThongTinChung(this.userInfoItem.data.userId).subscribe((result) => {
      if (result.code === SUCCESS_RESPONSE) {
        this.target = result.data;
        this.target.birthday = this.target.birthday ? new ConvertDatePipe().transform(this.target.birthday) : null;
        this.dataOrganization = this.listAllOrganization.find(c => c.id === this.target.organizationId);
        if (this.target.image) {
          this.imgSrc = this.dom.bypassSecurityTrustResourceUrl(this.target.image);
        }
      } else {
        this.commonService.toastrDanger(result.message);
      }
    });
  }

  listAllOrganization: any;
  LoadOrganization() {
    this.organizationService.qldtApiV1OrganizationGetBystatusOrganization(TypeStatus.SuDung).subscribe(res => {
      if (res.code === SUCCESS_RESPONSE) {
        this.listAllOrganization = res.data;
        this.loadData();
      } else {
        this.commonService.toastrDanger(res.message);
      }
    });
  }

  save() {
    this.target.birthday = this.target.birthday ? new Date(this.target.birthday) : null;
    this.target.organizationId = this.dataOrganization.id;
    this.userInfoService.SaveUserInfor(this.target).subscribe((result) => {
      if (result.code === SUCCESS_RESPONSE) {
        this.commonService.toastrSuccess(result.message);
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrDanger(result.message);
        this.dialogRef.close(false);
      }
    });
  }

  imgSrc: any;
  files: any;
  async chooseImg(files) {
    if (files.length === 0) {
      return;
    } else {
      this.target.image = await this.getBase64FromImage(files);
      this.imgSrc = this.dom.bypassSecurityTrustResourceUrl(this.target.image);
    }
  }

  getBase64FromImage(file): Promise<string> {
    return new Promise((res) => {
      this.fileReader.onload = (e) => {
        res(this.fileReader.result as string);
      };
      this.fileReader.readAsDataURL(file[0]);
    });
  }

  showImg() {
    this.dialog
      .open(ViewImageComponent, this.commonService.configDialog('50%', { url: this.imgSrc }))
      .afterClosed()
      .subscribe((result) => {});
  }

  deleteImg() {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('30%', {
          title: 'Xác nhận',
          message: `Bạn có muốn thu hồi ảnh đại diện?`,
          color: 'text-warning',
          icon: 'exclamation-triangle',
          isAppend: true
        })
      )
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.imgSrc = null;
          this.target.image = null;
        }
      });
  }
}
