import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-bars',
  templateUrl: './bars.component.html',
  styleUrls: ['./bars.component.scss']
})
export class IconBarsComponent  {

  constructor() { }

}
