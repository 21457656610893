import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-info-circle',
  templateUrl: './icon-info-circle.component.html',
  styleUrls: ['./icon-info-circle.component.scss'],
})
export class IconInfoCircleComponent {
  constructor() {}
}
