import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ChangePasswordModel, QldtApiService, ThongTinNguoiDungModel, ThongTinNguoiDungModelApiResponse } from '../../../../@core/data/qldt.service';


@Injectable({
    providedIn: 'root',
})
export class UserInfoService {
    constructor (
        private api: QldtApiService
    ) {}

    GetThongTinChung(key: number): Observable<ThongTinNguoiDungModelApiResponse> {
        return this.api.qldtApiV1SysUserGetThongTinChungById(key);
    }

    SaveUserInfor(body: ThongTinNguoiDungModel): Observable<ApiResponse> {
        return this.api.qldtApiV1SysUserSaveUserInfor(body);
    }
}
