<form #frm="ngForm">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">Thông báo</div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          <div class="form-group">
            <label [innerHtml]="label"></label>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="text-right">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <ngx-btn-confirm (click)="close(true)"></ngx-btn-confirm>&nbsp;
          <ngx-btn-close (click)="close(false)"></ngx-btn-close>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
