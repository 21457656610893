<form #frm="ngForm" (ngSubmit)="save()">
    <nb-card>
      <nb-card-header class="nb-card-header">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">Thông tin người dùng</div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                        <label class="control-label required">Đơn vị công tác</label>
                        <kendo-dropdowntree name="parentid" kendoDropDownTreeExpandable
                          [kendoDropDownTreeFlatBinding]="listAllOrganization" [textField]="'title'" [valueField]="'id'"
                          #parentid="ngModel" parentIdField="parentid" [(ngModel)]="dataOrganization">
                        </kendo-dropdowntree>
                      </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                      <label class="control-label">Mã số</label>
                      <input autocomplete="false" type="text" class="form-control" name="taxcode" [(ngModel)]="target.taxcode" />
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                      <label class="control-label required">Họ tên</label>
                      <input autocomplete="false" type="text" class="form-control" name="fullName" [(ngModel)]="target.fullName" required/>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                      <label class="control-label required">Ngày sinh</label>
                      <input type="date" class="form-control" [(ngModel)]="target.birthday" name="birthday"
                        #birthday="ngModel" maxlength="200" required/>
                    </div>
                  </div>

                  <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                      <label class="control-label">Số điện thoại</label>
                      <input autocomplete="false" type="text" class="form-control" name="phone" maxlength="11" [(ngModel)]="target.phone" />
                    </div>
                  </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
                <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
                    <div class="form-group">
                      <label class="control-label">Ảnh đại diện</label>
                      <br />
                      <div>
                        <div class="avatar-upload">
                          <div class="avatar-edit">
                            <input type="file" id="btn-chooseImg" accept=".png, .jpg, .jpeg" (change)="chooseImg($event.target.files)" />
                          </div>
                          <div class="avatar-preview">
                            <div id="imagePreview">
                              <div *ngIf="!imgSrc" class="backgroudAvatar">
                                <i class="fas fa-user sizeImgAvatar" *ngIf="!imgSrc"></i>
                              </div>
                              <img [src]="imgSrc" class="avatar-preview" *ngIf="imgSrc" style="padding: 10px 0px 10px 0px; width: 60%;" (click)="showImg()"/>
                            </div>
                          </div>
                          <div>
                            <div class="backgroud-del-btn" *ngIf="imgSrc">
                              <ngx-icon-delete class="del-btn" (click)="deleteImg()" ></ngx-icon-delete> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </nb-card-body>
      <nb-card-footer class="text-right">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
            <ngx-btn-submit [disabled]="frm.invalid"></ngx-btn-submit>
            &nbsp;
            <ngx-btn-close mat-dialog-close></ngx-btn-close>
          </div>
        </div>
      </nb-card-footer>
    </nb-card>
  </form>
  