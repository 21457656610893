import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-plus',
  templateUrl: './icon-plus.component.html',
  styleUrls: ['./icon-plus.component.scss']
})
export class IconPlusComponent  {

  constructor() { }

}
