<nb-card>
  <nb-card-body>
    <div class="row">
      <div
        class="col-sm-12 col-md-12 col-xs-12 col-lg-12"
        #fullScreen
        style="text-align: center; display: flex; justify-content: center"
      >
        <img [src]="imgSrc" width="500px" height="500px" />
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
        <ngx-btn-close mat-dialog-close></ngx-btn-close>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
<!-- <iframe [src]="imgSrc" type="application/pdf" height="100%" width="100%" style="height: 400px;width: 400px" ></iframe> -->
