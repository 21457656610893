import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-folder',
    templateUrl: './icon-folder.component.html',
    styleUrls: ['./icon-folder.component.scss']
})
export class IconFolderComponent {
    constructor() {
    }
}
