import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-site-map',
    templateUrl: './icon-site-map.component.html',
    styleUrls: ['./icon-site-map.component.scss']
})
export class IconSiteMapComponent {
    constructor() {
    }
}
