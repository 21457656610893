import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-search-plus',
  templateUrl: './icon-search-plus.component.html',
  styleUrls: ['./icon-search-plus.component.scss'],
})
export class IconSearchPlusComponent {
  constructor() {}
}
