import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AutoLoginGuard } from './@core/auth/auto-login.guard';
import { AuthGuard } from './@core/auth/auth.guard';
import { AboutStyleTwoComponent } from './home/pages/about-style-two/about-style-two.component';
import { AboutComponent } from './home/pages/about/about.component';
import { BlogDetailsComponent } from './home/pages/blog-details/blog-details.component';
import { BlogRightSidebarComponent } from './home/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogComponent } from './home/pages/blog/blog.component';
import { ContactComponent } from './home/pages/contact/contact.component';
import { FaqComponent } from './home/pages/faq/faq.component';
import { FeaturesComponent } from './home/pages/features/features.component';
import { HomeDemoSixComponent } from './home/pages/home-demo-six/home-demo-six.component';
import { LoginComponent } from './home/pages/login/login.component';
import { PartnerComponent } from './home/pages/partner/partner.component';
import { PricingComponent } from './home/pages/pricing/pricing.component';
import { ProjectDetailsComponent } from './home/pages/project-details/project-details.component';
import { ProjectsComponent } from './home/pages/projects/projects.component';
import { ServicesComponent } from './home/pages/services/services.component';
import { TeamComponent } from './home/pages/team/team.component';
//import { AutoLoginGuard } from './@core/auth/auto-login.guard';

export const routes: Routes = [

  { path: "", redirectTo: "home-page", pathMatch: "full" },
  // {
  //   path: "home",
  //   loadChildren: () =>
  //     import("./pages/home/home.module").then(
  //       (m) => m.HomeModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  {
    path: "dang-ky-hoc-vien",
    loadChildren: () =>
      import("./pages/hoc-vien-tudangky/hoc-vien-tudangky.module").then(
        (m) => m.HocVienTuDangKyModule
      ),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./module/module.module").then((m) => m.ModuleModule),
    canActivate: [AuthGuard],
  },
  {
    path: "home-page",
    loadChildren: () =>
      import("./home/home.module").then(
        (m) => m.HomeClientModule
      ),
  },
  { path: "**", redirectTo: "home-page" },

];

// const config: ExtraOptions = {
//   useHash: true,
// };

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }
    )
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
