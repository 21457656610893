import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-copy',
    templateUrl: './icon-copy.component.html',
    styleUrls: ['./icon-copy.component.scss']
})
export class IconCopyComponent {
    constructor() {
    }
}
