import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-icon-ellipsis',
  templateUrl: './icon-ellipsis.component.html',
  styleUrls: ['./icon-ellipsis.component.scss']
})
export class IconEllipsisComponent  {
  @Input() title: string;
  constructor() { }

}
