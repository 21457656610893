import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import {
  CommonService,
  ConvertDatePipe,
} from '../../../../@core/customs/common.service';
import {
  PAGE_SIZE,
  STATUS_ACTION,
  SUCCESS_RESPONSE,
} from '../../../../@core/customs/constants';
import {
  PageModel,
  QldtApiService,
  SysLogActionSearchModel,
} from '../../../../@core/data/qldt.service';
import { DialogConfirmComponent } from '../../../../@theme/components/template/dialog/dialog-confirm/dialog-confirm.component';
import { NbTabComponent } from '@nebular/theme';

@Component({
  selector: 'ngx-nhat-ky-he-thong',
  templateUrl: './nhat-ky-he-thong.component.html',
  styleUrls: ['./nhat-ky-he-thong.component.scss'],
})
export class NhatKyHeThongComponent implements OnInit {
  constructor(
    private commonService: CommonService,
    private service: QldtApiService,
    @Inject(MAT_DIALOG_DATA) private data: { data: any},

  ) {}

  pageModel: SysLogActionSearchModel = new SysLogActionSearchModel({
    search: null,
    timeEnd: null,
    timeStart: null,
    currentPage: 1,
    pageSize: PAGE_SIZE,
    userId: null
  });

  target: any = {
    data: [],
  };


  ngOnInit(): void {
    console.log(this.data.data)
    this.loadData();
  }

  loadData() {
    const model: SysLogActionSearchModel = new SysLogActionSearchModel({
      search: this.pageModel.search,
      timeEnd: null,
      timeStart: null,
      currentPage: this.pageModel.currentPage,
      pageSize: this.pageModel.pageSize,
    });
    model.timeEnd = this.pageModel.timeEnd ? new Date(this.pageModel.timeEnd) : null;
    model.timeStart = this.pageModel.timeStart ? new Date(this.pageModel.timeStart) : null;
    model.userId = this.data.data;

    this.service.qldtApiV1SyslogActionPagingSyslogToUser(model).subscribe((res) => {
        if (res.code === SUCCESS_RESPONSE) {
          this.target = res.data;
        }
      });
  }



  search() {
    this.pageModel.currentPage = 1;
    this.loadData();
  }

  refresh() {
    this.pageModel.currentPage = 1;
    this.pageModel.timeStart = null;
    this.pageModel.search = null;
    this.loadData();
  }
}
