<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">Phần mềm Quản lý đào tạo</a>
    <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
      <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>
  </div>

</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action icon="message-square-outline" [badgeDot]="checkHasNewNotiMess" (click)="changeActionMessage()"
    nbPopoverPlacement="bottom" badgeStatus="danger" [nbPopover]="templateRefMessage">
    <ng-template #templateRefMessage >
      <nb-card accent="info" style="margin-bottom: 0px; width: 400px; max-height: 600px" *ngIf="showMessageBox">
        <nb-card-header>
          <nb-form-field>
            <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
            <input type="text" fullWidth="true" placeholder="Nhập tên/email ..." (keydown.enter)="search()" [(ngModel)]="searchUser" nbInput
            id="searchUser">
          </nb-form-field>
        </nb-card-header>
        <nb-card-body>
          <nb-list nbInfiniteList [threshold]="500" style="cursor: pointer;">
            <nb-list-item *ngFor="let item of dataUserTemplate; let i = index;">
              <div class="row" style="width: 100%; font-size: 0.9rem !important;" (click)="Chat(item)">
                <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                  <div class="row">
                    <div class="col-sm-2 col-md-2 col-xs-2 col-lg-2" style="padding: 0px;">
                      <div class="online" *ngIf="item.isOnline">
                        <i class="fas fa-circle" style="color: green;"></i>
                      </div>
                      <img *ngIf="item.image" [src]="item.image" style="border-radius: 50%; height: 50px; width: 50px;"/>
                    </div>
                    <div class="col-sm-10 col-md-10 col-xs-10 col-lg-10">
                      <div class="contact-details">
                        <div class="contact-name">{{item.fullname}}</div>
                        <div class="contact-email">{{item.teamsmail}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nb-list-item>
          </nb-list>
        </nb-card-body>
        <nb-card-footer>
          <div (click)="loadNextUser()" class="loadmore" style="cursor: pointer;">Tải thêm ...</div>
        </nb-card-footer>
      </nb-card>
    </ng-template>
  </nb-action>
    <nb-action icon="bell-outline" (click)="clickBell()" [badgeDot]="checkHasNewNoti" nbPopoverPlacement="bottom"
      badgeStatus="danger" [nbPopover]="templateRef">
      <ng-template #templateRef>
        <nb-card accent="info" style="margin-bottom: 0px; width: 400px; max-height: 400px" *ngIf="showNotification">
          <nb-card-header *ngIf="totalNewNotification.length > 0">
            <span>Bạn có <b>{{totalNewNotification}}</b> thông báo mới !</span>
          </nb-card-header>
          <nb-list nbInfiniteList [threshold]="500" style="cursor: pointer;">
            <nb-list-item style="position: relative;" *ngFor="let item of dataNoti; let i = index;" (click)="clickNoti(item)">
              <nb-news-post>
                <div class="row" style="width: 100%; font-size: 0.9rem !important;">
                  <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6">
                    <label>{{item.title}}</label>
                  </div>
                  <div class="col-sm-6 col-md-6 col-xs-6 col-lg-6 text-right align-middle">
                    <b>Cách đây {{item.duration}} {{item.typeDuration}}</b>
                    &nbsp;
                    <nb-icon (click)="deleteNotification(i, item.id)" icon="close-outline" status="danger"
                      [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                  </div>
                  <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
                    <label>{{item.content}}</label>
                  </div>
                </div>
              </nb-news-post>
              <nb-badge *ngIf="item.status === 0" text="new" status="primary" position="top right"></nb-badge>
            </nb-list-item>
          </nb-list>
          <!-- <nb-card-footer class="text-center">
            <span><i>Xem thêm ...</i></span>
          </nb-card-footer> -->
        </nb-card>
      </ng-template>
    </nb-action>

    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.fullName"
        [picture]="user?.picture" (click)="selectToolbar()" color="#cccccc">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
