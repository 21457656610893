import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-minimize',
  templateUrl: './icon-minimize.component.html',
  styleUrls: ['./icon-minimize.component.scss']
})
export class IconMinimizeComponent  {

  constructor() { }

}
