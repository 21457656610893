<nb-card>
  <nb-card-header class="nb-card-header">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
        <h5 class="title-header" style="text-transform: uppercase;">Nhật ký hệ thống</h5>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 ">
        <div class="input-group">
          <input type="text" class="form-control" [(ngModel)]="pageModel.search" placeholder="Nhập nội dung tìm kiếm"
            name="seach" (keydown.enter)="search();">&nbsp;
          <ngx-btn-search (click)="search()"></ngx-btn-search>&nbsp;
          <ngx-btn-refresh (click)="refresh();"></ngx-btn-refresh>
        </div>
      </div>
    </div>
    <br>
    <div class="row" style="overflow: auto;">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-center align-middle" style="width: 50px;">STT</th>
              <th class="text-center align-middle" style="min-width: 200px;">Tên hành động</th>
              <th class="text-center align-middle" style="width: 200px;">Địa chỉ IP</th>
              <th class="text-center align-middle" style="width: 200px;">Thời gian truy cập</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of target.data; let i = index;">
              <td class="text-center">{{(pageModel.currentPage - 1) * pageModel.pageSize + i + 1 }}</td>
              <td>{{item.title}}</td>
              <td class="text-center">{{item.ipClient}}</td>
              <td class="text-center">{{item.timeStart | date:'dd/MM/yyyy h:mm:ss'}}</td>
            </tr>
          </tbody>
        </table>
        <div class="row" *ngIf="target?.recordsCount > 0">
          <div class="col-md-4 col-sm-4 col-xs-4 col-lg-4">
            <div style="float: left;">
              Tổng số bản ghi: {{target?.recordsCount}}
            </div>
          </div>
          <div class="col-md-8 col-sm-8 col-xs-8 col-lg-8">
            <div style="float: right;">
              <ngx-pagination (pageChange)="pageModel.currentPage = $event; loadData()"
                (pageSize)="pageModel.pageSize = $event; loadData()" [currentPage]="pageModel.currentPage"
                [totalPages]="target.totalPage">
              </ngx-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
        <ngx-btn-close mat-dialog-close></ngx-btn-close>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
