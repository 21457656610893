import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NB_WINDOW_CONTEXT } from "@nebular/theme";
import { Message, User } from "@progress/kendo-angular-conversational-ui";
import { Socket } from "ngx-socket-io";
import { Observable, fromEvent } from "rxjs";
import { TokenService } from "../../../@core/auth/token.service";
import {
  AttFileattachmentEntity,
  ChatHistoryInsertWithFileModel,
  ChatHistoryModel,
  ChatHistoryPageModel,
  FileParameter,
  ItemChatTemplate,
  ItemNotification,
  QldtApiService,
} from "../../../@core/data/qldt.service";
import {
  FILE_FOLDER,
  SUCCESS_RESPONSE,
  TYPE_FILE,
} from "../../../@core/customs/constants";
import { TextAreaComponent } from "@progress/kendo-angular-inputs";
import { SVGIcon, imageIcon, xCircleIcon } from "@progress/kendo-svg-icons";
import { CommonService } from "../../../@core/customs/common.service";
import { ScrollDispatcher } from "@angular/cdk/scrolling";

@Component({
  selector: "ngx-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(NB_WINDOW_CONTEXT) public dataRef: { item: any },
    private socket: Socket,
    private tokenService: TokenService,
    private qldtService: QldtApiService,
    private commonService: CommonService,
    private scrollDispatcher: ScrollDispatcher
  ) {}

  @ViewChild("scrollContainer") scrollContainer: ElementRef;
  containerElement: any;
  scrollHeight: number = 0;
  onHotScroll(event: Event) {
    this.containerElement =
      this.scrollContainer["messageList"]["nativeElement"];
    this.scrollHeight =
      this.containerElement.scrollHeight > this.scrollHeight
        ? this.containerElement.scrollHeight
        : this.scrollHeight;
    if (this.containerElement.scrollTop === 0 && this.isLoadMessage === false) {
      this.LoadDataMessage(1);
    }
  }

  scrollUpChat() {
    //đang trong quá trình phát triển :)))
    this.containerElement.scrollTop = 1; // điều kiện để không tự động load thêm tin nhắn
  }

  scrollDownChat() {
    //đang trong quá trình phát triển :)))
    this.containerElement.scrollTop = this.scrollHeight;
  }
  user: any;
  async ngOnInit(): Promise<void> {
    console.log(this.dataRef.item);
    this.tokenService.getUserInfo().subscribe((users: any) => {
      this.user = users;
    });
    this.loadChatTemplate();
    await this.LoadDataMessage(0);
    this.socket.emit("join_room", [this.user.id, this.dataRef.item.id]);
    this.receiveMessage().subscribe((message) => {
      if (this.isSend === false) {
        if (message["msg"] !== "") {
          let item = {
            author: this.bot,
            text: message["msg"],
            attachments: [],
          };
          this.messages = [...this.messages, item];
        } else {
          this.loadNewMessage();
        }
      } else {
        this.isSend = false;
      }
    });

    this.socket.fromEvent("sendTheMessage").subscribe((message) => {
      console.log("-:- " + JSON.stringify(message));
      let item = {
        author: this.bot,
        text: message["msg"],
        attachments: [],
      };
      this.messages = [...this.messages, item];
    });
  }

  dataChatTemplate: ItemChatTemplate[] = [];
  loadChatTemplate() {
    this.qldtService
      .qldtApiV1ChatTemplateGetByuseridChatTemplate(this.user.id)
      .subscribe((res) => {
        if (res.code === SUCCESS_RESPONSE) {
          this.dataChatTemplate = res.data;
        }
      });
  }

  isHidden: boolean = true;
  dataChatTemplateData: ItemChatTemplate[] = [];
  changeText(event) {
    if (event.charAt(0) == "/") {
      this.isHidden = false;
      this.dataChatTemplateData = this.dataChatTemplate.filter((x) =>
        x.recommend.includes(event.split("/")[1])
      );
    } else {
      this.isHidden = true;
    }
  }

  message = "";
  ChooseTemplate(content) {
    this.message = content;
    this.isHidden = true;
  }

  loadNewMessage() {
    this.qldtService
      .qldtApiV1ChatHistoryGetNewMessage(this.pageModel)
      .subscribe((res) => {
        if (res.code === SUCCESS_RESPONSE) {
          let item = {
            author: this.bot,
            text: res.data.content,
            timestamp: res.data.createdTime,
            attachments: [
              {
                contentType: "",
                content: res.data.listFile[0].fileId,
                title: res.data.listFile[0].fileName,
                subtitle: res.data.listFile[0].imageBase64,
              },
            ],
          };
          this.messages = [...this.messages, item];
        }
      });
  }

  pageModel: ChatHistoryPageModel = new ChatHistoryPageModel({
    currentPage: 1,
    pageSize: 20,
    idUserRecive: this.dataRef.item.id,
  });

  target: any = {
    data: [],
  };
  isLoadMessage: boolean = false;
  async LoadDataMessage(index: number) {
    this.isLoadMessage = true;
    this.pageModel.idUserSend = this.user.id;
    const res = await this.qldtService
      .qldtApiV1ChatHistoryPagingChatHistory(this.pageModel)
      .toPromise();
    if (res.code === SUCCESS_RESPONSE) {
      this.target.data = this.target.data.concat(res.data.data);
      this.pageModel.currentPage++;
      if (index === 0) {
        this.buildMessageBox(res.data.data);
      } else {
        this.buildMessageBoxTemp(res.data.data);
      }
    } else {
      this.isLoadMessage = false;
    }
  }

  buildMessageBoxTemp(data: ChatHistoryModel[]) {
    this.messagesTemp = [];
    data.forEach((element) => {
      if (element.sender === this.user.id) {
        if (element.content) {
          let item = {
            author: this.user1,
            text: element.content,
            timestamp: element.createdTime,
            attachments: [],
          };
          this.messagesTemp = [...this.messagesTemp, item];
        } else {
          let item = {
            author: this.user1,
            text: element.content,
            timestamp: element.createdTime,
            attachments: [
              {
                contentType: "",
                content: element.listFile[0].fileId,
                title: element.listFile[0].fileName,
                subtitle: element.listFile[0].imageBase64,
              },
            ],
          };
          this.messagesTemp = [...this.messagesTemp, item];
        }
      } else if (element.reciver === this.user.id) {
        if (element.content) {
          let item = {
            author: this.bot,
            text: element.content,
            timestamp: element.createdTime,
            attachments: [],
          };
          this.messagesTemp = [...this.messagesTemp, item];
        } else {
          let item = {
            author: this.bot,
            text: element.content,
            timestamp: element.createdTime,
            attachments: [
              {
                contentType: "",
                content: element.listFile[0].fileId,
                title: element.listFile[0].fileName,
                subtitle: element.listFile[0].imageBase64,
              },
            ],
          };
          this.messagesTemp = [...this.messagesTemp, item];
        }
      }
    });
    this.isLoadMessage = false;
    this.messages = this.messagesTemp.concat(this.messages);
  }

  buildMessageBox(data: ChatHistoryModel[]) {
    data.forEach((element) => {
      if (element.sender === this.user.id) {
        if (element.content) {
          let item = {
            author: this.user1,
            text: element.content,
            timestamp: element.createdTime,
            attachments: [],
          };
          this.messages = [...this.messages, item];
        } else {
          let item = {
            author: this.user1,
            text: element.content,
            timestamp: element.createdTime,
            attachments: [
              {
                contentType: "",
                content: element.listFile[0].fileId,
                title: element.listFile[0].fileName,
                subtitle: element.listFile[0].imageBase64,
              },
            ],
          };
          this.messages = [...this.messages, item];
        }
      } else if (element.reciver === this.user.id) {
        if (element.content) {
          let item = {
            author: this.bot,
            text: element.content,
            timestamp: element.createdTime,
            attachments: [],
          };
          this.messages = [...this.messages, item];
        } else {
          let item = {
            author: this.bot,
            text: element.content,
            timestamp: element.createdTime,
            attachments: [
              {
                contentType: "",
                content: element.listFile[0].fileId,
                title: element.listFile[0].fileName,
                subtitle: element.listFile[0].imageBase64,
              },
            ],
          };
          this.messages = [...this.messages, item];
        }
      }
    });

    this.containerElement =
      this.scrollContainer["messageList"]["nativeElement"];
    this.containerElement.querySelector(".scrollable-div");
    fromEvent(this.containerElement, "scroll")
      .pipe
      // Apply a debounce or throttle time if needed
      // debounceTime(300), // Debounce the events if needed
      // throttleTime(300), // Throttle the events if needed
      ()
      .subscribe((event: Event) => {
        this.onHotScroll(event);
      });
    this.isLoadMessage = false;
  }

  messages: Message[] = [];
  messagesTemp: Message[] = [];

  public readonly user1: User = {
    id: 1,
  };

  public readonly bot: User = {
    id: 0,
  };

  @ViewChild("messageBoxInput", { static: false })
  public messageBoxInput: TextAreaComponent;
  isSend: boolean = false;
  sendMessage() {
    const messageBox = this.messageBoxInput;
    const newMessage = messageBox.value;

    if (!newMessage) {
      return;
    }

    let item = {
      author: this.user1,
      text: newMessage,
      timestamp: new Date(),
    };
    this.messages = [...this.messages, item];
    this.socket.emit("sendTheMessage", {
      msg: newMessage,
      user: this.dataRef.item.fullname,
      createdAt: new Date(),
      from: this.user.id,
      to: this.dataRef.item.id,
    });
    this.socket.emit("message", {
      msg: newMessage,
      user: this.dataRef.item.fullname,
      createdAt: new Date(),
      from: this.user.id,
      to: this.dataRef.item.id,
    });
    let message = new ChatHistoryModel({
      content: newMessage,
      createdTime: null,
      id: null,
      reciver: this.dataRef.item.id,
      sender: this.user.id,
    });
    this.qldtService
      .qldtApiV1ChatHistoryInsertChatHistory(message)
      .subscribe((res) => {
        if (res.code !== SUCCESS_RESPONSE) {
          console.log("Erorr when save message");
        } else {
          let itemNoti: ItemNotification = new ItemNotification({
            content: "Bạn có tin nhắn mới từ " + this.user.fullName,
            createddate: new Date(),
            id: null,
            recivedid: this.dataRef.item.id,
            senderid: this.user.id,
            status: 0,
            title: "Tin nhắn mới",
            typeid: "1",
          });
          this.qldtService
            .qldtApiV1NotificationCreateNotification(itemNoti)
            .subscribe((resNoti) => {
              if (resNoti.code !== SUCCESS_RESPONSE) {
                console.log("Erorr when save notification new message");
              }
            });
        }
      });
    messageBox.value = null;
    messageBox.focus();
    this.isSend = true;
  }

  receiveMessage() {
    return new Observable((observer) => {
      this.socket.on("message", (message) => {
        observer.next(message);
      });
    });
  }

  ngOnDestroy() {
    this.socket.emit("leave_room", [this.user.id, this.dataRef.item.id]);
  }

  @ViewChild("uploadInput", { static: false })
  public inputFile: ElementRef;

  innerInputFocus = false;
  public selectedFile: File;
  public imageIcon: SVGIcon = imageIcon;
  public xCircleIcon: SVGIcon = xCircleIcon;
  selectedFiles: FileParameter[] = [];

  public onFileSelect(event: Event): void {
    this.selectedFiles = [];
    //Đoc file
    this.selectedFile = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onloadend = (data) => {
      //Lưu attFileattachment và ChatHistory
      const fileParameter: any = {
        data: this.selectedFile,
        fileName: this.selectedFile.name,
      };
      this.selectedFiles.push(fileParameter);
      this.commonService
        .uploadFile(FILE_FOLDER.DMHT.CHAT, this.selectedFiles)
        .subscribe((fileResult) => {
          const lstFile = fileResult.data.map(
            (element) =>
              new AttFileattachmentEntity({
                filename: element.fileName,
                recordid: null,
                fileid: element.id,
                id: element.id,
              })
          );
          const targetFileInsert: AttFileattachmentEntity[] = [];
          lstFile?.forEach((m) => {
            const item = new AttFileattachmentEntity();
            item.fileid = m.fileid;
            item.filename = m.filename;
            item.type = TYPE_FILE.DMHT.CHAT;
            item.isDirty = false;
            item.isNew = true;
            targetFileInsert.push(item);
          });
          let TargetInsertWithFile: ChatHistoryInsertWithFileModel =
            new ChatHistoryInsertWithFileModel({
              entity: new ChatHistoryModel({
                content: "",
                createdTime: null,
                id: null,
                reciver: this.dataRef.item.id,
                sender: this.user.id,
              }),
              lstFileAttachment: targetFileInsert,
            });
          this.qldtService
            .qldtApiV1ChatHistoryInsertWithFileChatHistory(TargetInsertWithFile)
            .subscribe((resInsert) => {
              if (resInsert.code === SUCCESS_RESPONSE) {
                let itemNoti: ItemNotification = new ItemNotification({
                  content: "Bạn có tin nhắn mới từ " + this.user.fullName,
                  createddate: new Date(),
                  id: null,
                  recivedid: this.dataRef.item.id,
                  senderid: this.user.id,
                  status: 0,
                  title: "Tin nhắn mới",
                  typeid: "1",
                });

                // Thêm tin nhắn vào ô chat
                const message = {
                  text: "",
                  author: this.user1,
                  timestamp: new Date(),
                  attachments: [
                    {
                      contentType: this.selectedFile.type,
                      content: targetFileInsert[0].fileid,
                      title: this.selectedFile.name,
                      subtitle: this.selectedFile.type.includes("image")
                        ? data.target.result.toString()
                        : null,
                    },
                  ],
                };
                this.messages = [...this.messages, message];

                // Chuyển tin nhắn qua socket
                this.socket.emit("sendTheMessage", {
                  msg: "",
                  user: this.dataRef.item.fullname,
                  createdAt: new Date(),
                  from: this.user.id,
                  to: this.dataRef.item.id,
                });
                this.socket.emit("message", {
                  msg: "",
                  user: this.dataRef.item.fullname,
                  createdAt: new Date(),
                  from: this.user.id,
                  to: this.dataRef.item.id,
                });

                this.qldtService
                  .qldtApiV1NotificationCreateNotification(itemNoti)
                  .subscribe((resNoti) => {
                    if (resNoti.code !== SUCCESS_RESPONSE) {
                      console.log("Erorr when save notification new message");
                    }
                  });
              }
            });
        });
    };
    this.messageBoxInput.focus();
    this.innerInputFocus = true;
    this.isSend = true;
    this.inputFile.nativeElement.value = "";
  }

  public onInputFocus(): void {
    this.innerInputFocus = true;
  }

  public onInputBlur(): void {
    this.innerInputFocus = false;
  }

  public clearValue(): void {
    if (!this.innerInputFocus) {
      this.messageBoxInput.value = null;
      this.messageBoxInput.focus();
      this.innerInputFocus = true;
    }
  }

  public onKeyDown(e: KeyboardEvent): void {
    const isEnter = e.keyCode === 13;

    if (!isEnter) {
      return;
    }

    const newLine = e.metaKey || e.ctrlKey;
    const enterOnly = !(e.shiftKey || e.metaKey || e.ctrlKey);

    if (enterOnly && this.innerInputFocus) {
      e.preventDefault();
      this.sendMessage();
    }
    if (newLine) {
      this.messageBoxInput.value += `\r\n`;
    }
  }

  downloadFile(item: any) {
    this.commonService.downloadFile(item.content);
  }
}
