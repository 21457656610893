import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "ngx-delete-dialog",
  templateUrl: "./delete-dialog.component.html",
  styleUrls: ["./delete-dialog.component.scss"],
})
export class DeleteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public label: string,
    private dialogRef: MatDialogRef<DeleteDialogComponent>
  ) {}

  close(result: boolean = false) {
    this.dialogRef.close(result);
  }
}
