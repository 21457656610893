import { Component } from '@angular/core';

@Component({
    selector: 'ngx-icon-delete',
    templateUrl: './icon-delete.component.html',
    styleUrls: ['./icon-delete.component.scss']
})
export class IconDeleteComponent {
    constructor() {
    }
}
