import { Component, OnInit } from '@angular/core';
import { TYPE_FILE } from '../../../@core/customs/constants';
import * as fs from 'file-saver';
import { CanBoService } from '../can-bo/can-bo.service';

@Component({
  selector: 'ngx-huong-dan-sd',
  templateUrl: './huong-dan-sd.component.html',
  styleUrls: ['./huong-dan-sd.component.scss']
})
export class HuongDanSuDungComponent implements OnInit {
  constructor(
    private service: CanBoService,
  ) { }
  typeWeb= TYPE_FILE.HUONGDAN.WEB;
  typeMobile= TYPE_FILE.HUONGDAN.MOBILE;
  ngOnInit(): void {
    console.log('hướng dẫn sử dụng')
  }
  downloadTemplateFile() {
    this.service.downloadTemplateFile(TYPE_FILE.HUONGDAN.HUONGDAN_ALL).subscribe(res => {
      fs.saveAs(res.data, res.fileName);
    });
  }
}
