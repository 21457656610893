
import { DatePipe } from '@angular/common';
import { Component, Injectable, Pipe, PipeTransform } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import {
  HEIGHT_DIALOG,
  TITLE_NOTICE,
  WIDTH_DIALOG,
  SUCCESS_NOTICE,
  ROLE,
} from './constants';
import { FileParameter, FileResponse, QldtApiService, ResponseModelListApiResponse } from '../data/qldt.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenService } from '../auth/token.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { Worksheet } from 'exceljs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(
    private toastrService: NbToastrService,
    private _service: QldtApiService,
    private spinner: NgxSpinnerService,
    private tokenService: TokenService
  ) {}

  getDuAnDauTu() {
    return this.tokenService.getProjectInvest();
  }

  getUserInfo() {
    return this.tokenService.getUserInfo();
  }

  getCurrentWeekOfCurrentYear() {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor(
      (<any>currentDate - <any>startDate) / (24 * 60 * 60 * 1000)
    );
    return Math.ceil(days / 7);
  }

  getWeekNumber(d: Date) {
    d = new Date(+d);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNo = Math.ceil(((<any>d - <any>yearStart) / 86400000 + 1) / 7);
    return [d.getFullYear(), weekNo];
  }

  weeksInYear(year) {
    const d = new Date(year, 11, 31);
    const week = this.getWeekNumber(d)[1];
    return week === 1 ? 52 : week;
  }

  mapRole(lstRole: string[]): IRole {
    const role: IRole = {
      XEM: lstRole.some((x) => x === ROLE.XEM),
      THEM: lstRole.some((x) => x === ROLE.THEM),
      CAPNHAT: lstRole.some((x) => x === ROLE.CAPNHAT),
      XOA: lstRole.some((x) => x === ROLE.XOA),
      IMPORT: lstRole.some((x) => x === ROLE.IMPORT),
      CHIASE: lstRole.some((x) => x === ROLE.CHIASE),
      THUHOI: lstRole.some((x) => x === ROLE.THUHOI),
      DONGBO: lstRole.some((x) => x === ROLE.DONGBO),
    };
    return role;
  }

  // getListRoleForMenu(state: string): Observable<StringListApiResponse> {
  //   const body = new SelectRoleRequestModel({
  //     state: state,
  //   });
  //   return this.appQthtApiService.appQthtApiSysuserSelectrole(body);
  // }



  toastrSuccess(message?: string, title?: string) {
    this.toastrService.success(
      message ?? SUCCESS_NOTICE,
      title ?? TITLE_NOTICE,
      { icon: "" }
    );
  }

  base64FileImg(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
     return reader.result.toString();
    };
  }

  listFileString: string[] = [];
  base64ListFile(event: File[]) {
    for (let i = 0; i < event.length; i++) {
    const reader = new FileReader();
    reader.readAsDataURL(event[i]);
    reader.onload = () => {
      this.listFileString.push(reader.result.toString());
    };
  }
    return this.listFileString;
  }

  uploadFile(folder: string, files: FileParameter[]): Observable<ResponseModelListApiResponse> {
    return this._service.qldtApiV1FileUploadFile(folder, files);
  }
  newGuid() {
      return 'xx-xx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x1 | 0x2);
        return v.toString(5);
      });
    }
  downloadFile(fileId: number): void {
    this._service.qldtApiV1FileDownloadFile(fileId).subscribe(response => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const a = document.createElement('a');
      a.setAttribute('style', 'display:none;');
      document.body.appendChild(a);
      a.download = b64DecodeUnicode(response.headers['x-file-name']);
      a.href = URL.createObjectURL(blob);
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
      if (response.status !== 200) {
        this.toastrDanger('Lỗi khi thực hiện tác vụ download.');
      }
    });

    function b64DecodeUnicode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(str).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }
  }

  getFile(fileId: number): Observable<FileResponse> {
    return this._service.qldtApiV1FileDownloadFile(fileId);
  }

  durationDefault = 3000;
  toastrPrimary(message: string, duration?: number, title?: string) {
    this.toastrService.primary(message, title ?? TITLE_NOTICE, {
      icon: "",
      duration: duration ?? this.durationDefault,
    });
  }

  toastrWarning(message: any, duration?: number, title?: string) {
    this.toastrService.warning(message, title ?? TITLE_NOTICE, {
      icon: "",
      duration: duration ?? this.durationDefault,
    });
  }

  toastrDanger(message: string, duration?: number, title?: string) {
    this.toastrService.danger(message, title ?? TITLE_NOTICE, {
      icon: "",
      duration: duration ?? this.durationDefault,
    });
  }

  toastrInfo(message: string, duration?: number, title?: string) {
    this.toastrService.info(message, title ?? TITLE_NOTICE, {
      icon: "",
      duration: duration ?? this.durationDefault,
    });
  }

  toastrShow(message: string, duration?: number, title?: string) {
    this.toastrService.show(message, title ?? TITLE_NOTICE, {
      icon: "",
      duration: duration ?? this.durationDefault,
    });
  }

  toastrDefault(message: string, duration?: number, title?: string) {
    this.toastrService.default(message, title ?? TITLE_NOTICE, {
      icon: "",
      duration: duration ?? this.durationDefault,
    });
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  getListYear(value: number = 16): number[] {
    const lstYear: number[] = [];
    for (
      let i = new Date().getFullYear() + value;
      i >= new Date().getFullYear() - value;
      i--
    ) {
      lstYear.push(i);
    }
    return lstYear;
  }

  configDialog(widthDialog: string, data: any): MatDialogConfig {
    const DIALOG_CONFIG = new MatDialogConfig();
    DIALOG_CONFIG.disableClose = true;
    DIALOG_CONFIG.width = widthDialog;
    DIALOG_CONFIG.height = "auto";
    DIALOG_CONFIG.maxWidth = WIDTH_DIALOG;
    DIALOG_CONFIG.maxHeight = HEIGHT_DIALOG;
    DIALOG_CONFIG.data = data;
    return DIALOG_CONFIG;
  }

  configDialogFullscreen(widthDialog: string, data: any): MatDialogConfig {
    const DIALOG_CONFIG = new MatDialogConfig();
    DIALOG_CONFIG.disableClose = true;
    DIALOG_CONFIG.width = widthDialog;
    DIALOG_CONFIG.height = "auto";
    DIALOG_CONFIG.maxWidth = "100%";
    DIALOG_CONFIG.maxHeight = HEIGHT_DIALOG;
    DIALOG_CONFIG.data = data;
    return DIALOG_CONFIG;
  }

    /**
   * thiết lập style khi export excel
   * @param worksheet worksheet
   * @param colStart cột đầu tiên
   * @param colEnd  cột cuối cùng
   * @param rowHeaderStart dòng tiêu đề bắt đầu
   * @param rowHeaderEnd dòng tiêu đề kết thúc
   * @param colCenter mảng cột căn giữa
   * @param colRight mảng cột căn phải
   * @param rowBold mảng dòng in đậm
   * @param rowItalic mảng dòng in nghiêng
   * @param heightTitle chiều cao dòng tiêu đề
   * @param heightHeader mảng chiều cao dòng header của table
   */
    setStyleExport(
      worksheet: Worksheet,
      colStart: number,
      colEnd: number,
      rowHeaderStart: number,
      rowHeaderEnd: number,
      colCenter?: number[],
      colRight?: number[],
      rowBold?: number[],
      rowItalic?: number[],
      heightTitle: number = 40,
      heightHeader: number[] = [25]
    ) {
      worksheet.mergeCells(1, colStart, 1, colEnd);
      worksheet.getCell(1, 1).style = {
        font: { bold: true, name: 'Times New Roman', size: 17 },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }
      };
      worksheet.getRow(1).height = heightTitle;
      for (let i = rowHeaderStart; i <= rowHeaderEnd; i++) {
        if (i - rowHeaderStart > heightHeader.length - 1) {
          worksheet.getRow(i).height = heightHeader[heightHeader.length - 1];
        } else {
          worksheet.getRow(i).height = heightHeader[i - rowHeaderStart];
        }
      }

      worksheet.eachRow((row, rowNumber) => {
        for (let colNumber = colStart; colNumber <= colEnd; colNumber++) {
          if (rowNumber >= rowHeaderStart && rowNumber <= rowHeaderEnd) {
            worksheet.getCell(rowNumber, colNumber).style = {
              border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
              font: { size: 13, name: 'Times New Roman', bold: true },
              alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' }
            };
          } else if (rowNumber > rowHeaderEnd) {
            if (colCenter?.includes(colNumber)) {
              worksheet.getCell(rowNumber, colNumber).style = {
                border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                font: {
                  size: 13,
                  name: 'Times New Roman',
                  bold: rowBold && rowBold.length > 0 ? rowBold.includes(rowNumber) : false,
                  italic: rowItalic && rowItalic.length > 0 ? rowItalic.includes(rowNumber) : false,
                },
                alignment: { wrapText: true, vertical: 'middle', horizontal: 'center' }
              };
            } else if (colRight?.includes(colNumber)) {
              worksheet.getCell(rowNumber, colNumber).style = {
                border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                font: {
                  size: 13,
                  name: 'Times New Roman',
                  bold: rowBold && rowBold.length > 0 ? rowBold.includes(rowNumber) : false,
                  italic: rowItalic && rowItalic.length > 0 ? rowItalic.includes(rowNumber) : false,
                },
                alignment: { wrapText: true, vertical: 'middle', horizontal: 'right' },
                numFmt: '#,##0'
              };
            } else {
              worksheet.getCell(rowNumber, colNumber).style = {
                border: { top: { style: 'thin' }, bottom: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' } },
                font: {
                  size: 13,
                  name: 'Times New Roman',
                  bold: rowBold && rowBold.length > 0 ? rowBold.includes(rowNumber) : false,
                  italic: rowItalic && rowItalic.length > 0 ? rowItalic.includes(rowNumber) : false,
                },
                alignment: { wrapText: true, vertical: 'middle', horizontal: 'left' }
              };
            }
          }
        }
      });
    }

    checkXepLoai(diem: number) {
      if (diem === null || diem === undefined || diem === 0) {
        return '';
      } else {
        if ( diem >= 9 && diem <= 10) {
          return 'Xuất sắc';
        } else if (diem >= 8 && diem < 9) {
          return 'Giỏi';
        } else if (diem >= 7 && diem < 8) {
          return 'Khá';
        } else if (diem >= 5 && diem < 7) {
          return 'Trung bình';
        } else if (diem >= 4 && diem < 5) {
          return 'Yếu';
        } else {
          return 'Kém';
        }
      }
    }
}

// tslint:disable-next-line: component-class-suffix
export class VitualScrollNgSelect {
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;
  vitualData = [];
  lstVitualScroll = [];
  bufferSize = 10;

  constructor() {}

  setDataForVitualScroll(lst: any[]) {
    this.vitualData = lst;
    this.lstVitualScroll = this.vitualData.slice(0, this.bufferSize);
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll({ end }) {
    if (this.loading || this.vitualData.length <= this.lstVitualScroll.length) {
      return;
    }

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.lstVitualScroll.length
    ) {
      this.fetchMore();
    }
  }
  private fetchMore() {
    const len = this.lstVitualScroll.length;
    const more = this.vitualData.slice(len, this.bufferSize + len);
    this.loading = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loading = false;
      this.lstVitualScroll = this.lstVitualScroll.concat(more);
    }, 200);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor() {}
  filter(array, string) {
    function convertDate(params: string, str: string) {
      if ((params !== null || params !== undefined) && params.length === 8) {
        try {
          const year = params.substr(0, 4);
          const month = params.substr(4, 2);
          const day = params.substr(6, 2);
          if (`${day}/${month}/${year}`.includes(str)) {
            return true;
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    }
    function checkData(params: string, str: string) {
      return params.toLowerCase().includes(str.toLowerCase()) ||
        convertDate(params, str)
        ? true
        : false;
    }
    if (
      (array !== null || array !== undefined) &&
      (string !== null || string !== undefined) &&
      string !== ''
    ) {
      return array.filter((o) =>
        Object.keys(o).some((k) =>
          typeof o[k] === 'string'
            ? checkData(o[k], string)
            : typeof o[k] === 'number'
            ? o[k].toString().includes(string.toLowerCase())
            : false
        )
      );
    }
    return array;
  }

  searchItem(string: string, obj: any) {
    function convertDate(params: string, str: string) {
      if ((params !== null || params !== undefined) && params.length === 8) {
        try {
          const year = params.substr(0, 4);
          const month = params.substr(4, 2);
          const day = params.substr(6, 2);
          if (`${day}/${month}/${year}`.includes(str)) {
            return true;
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    }

    function checkData(params: string, str: string) {
      return params.toLowerCase().includes(str.toLowerCase()) ||
        convertDate(params, str)
        ? true
        : false;
    }

    return Object.keys(obj).some((k) =>
      typeof obj[k] === 'string'
        ? checkData(obj[k], string)
        : typeof obj[k] === 'number'
        ? obj[k].toString().includes(string.toLowerCase())
        : false
    );
  }
}

@Pipe({
  name: 'pipeDate',
})
export class ConvertDatePipe implements PipeTransform {
  //#region CONVERT_DATE


  convertStringToDateImportExcel(target: any): Date {
    if (target) {
      let array = [];
      let a = new Date(target)
      if(Object.prototype.toString.call(target) === '[object Date]'){
        target  = this.transformExport(target)
      }
        array = target.split('/');
        if (array.length === 3) return new Date(array[2] + '-' + array[1] + '-' + array[0]);
    }
    return null;
  }

  transform(value: any) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'yyyy-MM-dd');
    return value;

  }
  transformCustom(value: any) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'yyyy-MM-dd HH:mm');
    return value;

  }
  transformExport(value: any) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'dd/MM/yyyy');
    return value;
  }

  convertDateToString(target: Date): string {
    if (target) {
      return this.transform(target.toString());
    }
    return null;
  }
  convertStringToDateString(target: string): string {
    if (target) {
      if (target.length === 8) {
        const year = target.substr(0, 4);
        const month = target.substr(4, 2);
        const day = target.substr(6, 2);
        return day + '/' + month + '/' + year;
      } else if (target.length === 10) {
        const year = target.substr(0, 4);
        const month = target.substr(5, 2);
        const day = target.substr(8, 2);
        return day + '/' + month + '/' + year;
      }
      return target;
    }
  }
  convertStringToDate(target: string): Date {
    if (target && target.length === 8) {
      const year = +target.substr(0, 4);
      const month = +target.substr(4, 2);
      const day = +target.substr(6, 2);
      return new Date(year, month, day);
    }
    return null;
  }

  convertStringVnToDate(target: string): Date {
    if (target && target.length === 10) {
      const year = +target.substr(6, 4);
      const month = +target.substr(3, 2);
      const day = +target.substr(0, 2);
      return new Date(year, month, day);
    }
    return null;
  }

  convertStringToFormatInput(target: string): string {
    if (target && target.length === 8) {
      const year = target.substr(0, 4);
      const month = target.substr(4, 2);
      const day = target.substr(6, 2);
      return year + '-' + month + '-' + day;
    }
    return target;
  }

  convertStringddMMyyyyToFormatTextVN(target: string) {
    if (target && target.length === 8) {
      const year = target.substr(0, 4);
      const month = target.substr(4, 2);
      const day = target.substr(6, 2);
      return 'Ngày ' + day + ' tháng ' + month + ' năm ' + year;
    }
    return target;
  }

  convertStringDisplayToDateString(target: string) {
    if (target && target.length === 10) {
      const year = target.substr(0, 4);
      const month = target.substr(5, 2);
      const day = target.substr(8, 2);
      return year + month + day;
    }
    return target;
  }
  //#endregion
}

export interface IRole {
  XEM: boolean;
  THEM: boolean;
  CAPNHAT: boolean;
  XOA: boolean;
  IMPORT: boolean;
  CHIASE: boolean;
  THUHOI: boolean;
  DONGBO: boolean;
}

export class Role implements IRole {
  XEM: boolean;
  THEM: boolean;
  CAPNHAT: boolean;
  XOA: boolean;
  IMPORT: boolean;
  CHIASE: boolean;
  THUHOI: boolean;
  DONGBO: boolean;
}
