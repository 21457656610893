
import {
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CommonService } from '../customs/common.service';
import { SUCCESS_RESPONSE } from '../customs/constants';
import { MenuService } from '../customs/menu.service';
import { QldtApiService, LoginModel, TokenForRefresh, IAuthenticateResponse, UserNotificationModel } from '../data/qldt.service';
import { ACCESS_TOKEN, TokenService } from './token.service';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  redirectUrl = "";
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  private static handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }

  private jwtHelper: JwtHelperService;
  private token?: string;
  constructor(
    private tokenService: TokenService,
    private commonService: CommonService,
    private _service: QldtApiService,
    private menuService: MenuService,
    private router: Router
  ) {
    this.loadToken();
    this.jwtHelper = new JwtHelperService();
    this.jwtHelper.tokenGetter = () => this.token;
    this.loadSavedAuthData();
  }

  getTokenDevice(userId: number) {
    Notification.requestPermission().then((res) => {
      if (res === 'granted') {
        const messaging = getMessaging();
        getToken(messaging, { vapidKey: environment.PUBLIC_VAPID_KEY }).then((token) => {
          if (token) {
            localStorage.setItem('tokenDevice', token);
            let _userNotifiction = new UserNotificationModel({
              userId: userId,
              token: token,
              isAndroid: 3,
            })
            this._service.qldtApiV1NotificationtypeInserUserNotification(_userNotifiction).subscribe((x) => {
            });
          }
        }).catch(error => {
          console.log(error);
        });
      }
      else {
        throw new Error('User denied permissions!');
      }
    })
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      var data = JSON.parse(payload.data.notification);
      var notification = new Notification(data.title, { body: data.body, icon: 'https://i.stack.imgur.com/Jzjhz.png?s=48&g=1', dir: 'auto' });
      setTimeout(function () {
        notification.close();
      }, 5000);
      this.commonService.toastrPrimary(data.body, 3000, data.title);

      return payload;
    });
  }
  popupCenter(title, body, w, h) {
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);
    return window.open(title, body, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  }

  loadToken() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  private static log(message: string): any {
    // console.log(message);
  }

  login(loginData: any, state: string): Observable<any> {
    this.tokenService.removeToken();
    // this.tokenService.removeRefreshToken();
    const body = new HttpParams()
      .set("username", loginData.username)
      .set("password", loginData.password)
      .set("grant_type", "password");

    const userLogin = new LoginModel({
      username: loginData.username,
      password: loginData.password,
    });
    this.menuService.resetMenuWhenLogin();
    return this._service.qldtApiV1AuthorizationAuthenticate(userLogin).pipe(
      tap((res) => {
        if (res.data) {
          this.tokenService.saveToken(res.data.jwtToken);
          this.token = res.data.jwtToken;
          const usInfor: IAuthenticateResponse = {
            id: res.data.id,
            fullName: res.data.fullName,
            username: res.data.username,
            picture: res.data.picture.toString(),
            organizationId: res.data.organizationId,
            jwtToken: res.data.jwtToken,
            role: res.data.role,
            refreshToken: res.data.refreshToken,
          };
          localStorage.setItem('img', usInfor.picture);
          usInfor.picture = null;
          this.tokenService.setUserInfo(
            usInfor,
            this.jwtHelper.getTokenExpirationDate(usInfor.jwtToken)
          );
          let stateRouter = "/home-page";
          if (state) {
            stateRouter = "/" + state + stateRouter;
          }
          this.router.navigate([stateRouter]).then(() => {
            this.commonService.toastrSuccess("Đăng nhập thành công !");
          });

          this.getTokenDevice(res.data.id); //setTokenDevice
        } else {
          this.commonService.toastrWarning(res.message);
        }
      }),
      catchError(AuthService.handleError)
    );
  }

  private loadSavedAuthData(): void {
    const tokenString = this.getSavedAuthData();
    this.setAuthData(tokenString);
  }

  private getSavedAuthData() {
    if (!localStorage) return null;
    return this.tokenService.getToken();
  }

  private setAuthData(tokenString: string): void {
    this.token = tokenString;
  }

  refreshToken(tag: string = null): Observable<any> {
    const body = new TokenForRefresh({
      refreshToken: tag,
      // token: this.tokenService.getToken()
    });
    return this._service.qldtApiV1AuthorizationRefreshToken(body).pipe(
      tap((res) => {
        if (res.code === SUCCESS_RESPONSE) {
          this.tokenService.removeToken();
          this.tokenService.saveToken(res.data.jwtToken);
        }
      }),
      catchError(AuthService.handleError)
    );
  }

  logout(): void {
    const tokenDevice = localStorage.getItem('tokenDevice');
    this._service.qldtApiV1NotificationtypeRemoveUserNotificationToken(tokenDevice).subscribe((res) => {
      if (res.code === SUCCESS_RESPONSE) {
        localStorage.removeItem('tokenDevice');
      }
    })
    this.tokenService.removeToken();
    // this.tokenService.removeRefreshToken();
    this.tokenService.removeCookie();
  }

  register(data: any): Observable<any> {
    // return this.http.post<any>(API_URL + 'oauth/signup', data)
    //   .pipe(
    //     tap(_ => AuthService.log('register')),
    //     catchError(AuthService.handleError)
    //   );
    return null;
  }

  secured(): Observable<any> {
    // return this.http.get<any>(API_URL + 'secret')
    //   .pipe(catchError(AuthService.handleError));
    return null;
  }

  checkTokenExpired(): boolean {
    const dt = this.tokenService.getCookieExpired();
    if (dt === null || dt < new Date()) {
      this.router.navigate(["/home-page/login"]).then((_) => false);
      return true;
    }
    return false;
  }

  routingMach(routing: string): boolean {
    let checkRouting = false;
    this.menuService.getRoutingSaveCheckRole().subscribe((result) => {
      if (result.length === 0) {
        this.menuService.getMenuByUserLogin().subscribe((menuLogin) => {
          if (menuLogin.code === SUCCESS_RESPONSE) {
            this.menuService.setRoutingSaveCheckRole(menuLogin.data);
            if (menuLogin.data.findIndex((c) => c.route === routing) !== -1) {
              checkRouting = true;
            }
          }
        });
      } else {
        if (result.findIndex((c) => c.route === routing) !== -1) {
          checkRouting = true;
        }
      }
    });
    return checkRouting;
  }

}
